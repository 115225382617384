import React from 'react';
import SpeedIcon from '@mui/icons-material/Speed';
import SensorIcon from '@mui/icons-material/SensorsRounded';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoardOutlined';
import Accordion from '../components/Accordion';
import Counter from '../widgets/Counter';
import { getDevicesApi } from '../api/api';
import Controller from '../widgets/Controller';
import Sensor from '../widgets/Sensor';
import LoadingSpinner from '../components/LoadingSpinner';
import AlertAccent from '../components/AlertAccent';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DeviceWidgets = {
  counter: Counter,
  controller: Controller,
  sensor: Sensor,
};

const Dashboard = ({ user }) => {
  const [loading, setLoading] = React.useState({ get: true, add: true, edit: true, delete: true });

  //devices - getting devices from backend
  const [devices, setDevices] = React.useState([]); //main variable
  const [devicesErr, setDevicesErr] = React.useState([]); //server respond variable
  const getDevices = async () => {
    setLoading({ ...loading, get: true });
    await getDevicesApi(
      function (data) {
        setLoading({ ...loading, get: false });
        setDevices(data);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setLoading({ ...loading, get: false });
        setDevicesErr((p) => [...p, err]);
      }
    );
  };

  React.useEffect(() => {
    const get = async () => {
      await getDevices();
    };
    get();
  }, []);

  React.useEffect(() => {
    createTypesArray();
  }, [devices]);

  const [types, setTypes] = React.useState([]);

  const createTypesArray = () => {
    var typesArr = [];
    devices.map((dev, index) => {
      typesArr = typesArr.concat(dev.description);
    });
    typesArr = Array.from(new Set(typesArr));

    setTypes(typesArr);
  };

  return (
    <>
      <div>
        <ToastContainer />
      </div>
      <div class="flex min-h-screen p-1 items-start justify-center bg-gradient-to-r from-[#ededed] to-[#dcdcdc]">
        {!loading.get ? (
          <>
            {devices.length !== 0 ? (
              <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-0 w-full">
                <>
                  {types.map((type, i) => {
                    return (
                      <div class="bg-white shadow-2xl rounded-lg m-2 p-3">
                        <p class="font-bold text-center">{type}</p>

                        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div class="inline-block min-w-full py-1 sm:px-6 lg:px-8">
                            <>
                              {devices.map((device, index) => {
                                return (
                                  typeof DeviceWidgets[device.type.key] !== 'undefined' &&
                                  type === device.description &&
                                  React.createElement(DeviceWidgets[device.type.key], {
                                    key: index,
                                    device: device,
                                  })
                                );
                              })}
                            </>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              </div>
            ) : (
              <div class="bg-white shadow-2xl rounded-lg px-6 pb-6 m-2">
                <div class="w-sm mt-6">
                  <AlertAccent
                    title="Устройства не добавленны!"
                    text="Для управления и отображения информации Вам необходимо добавить хотябы одно устройство"
                    showAlert={true}></AlertAccent>
                </div>
              </div>
            )}
          </>
        ) : (
          <LoadingSpinner error={devicesErr} loading={true}></LoadingSpinner>
        )}
      </div>
    </>
  );
};

export default Dashboard;
