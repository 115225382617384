import React from 'react';
import { getObjectsApi, getDevicesObjApi, addIntegrationApi, getIntegrationsApi, delIntegrationApi } from '../api/api';
import { getIntegrationsApi2, addIntegrationApi2, delIntegrationApi2 } from '../api/api2';
import Input from '../components/Input';
import Dropdown from '../components/Dropdown';
import LoadingSpinner from '../components/LoadingSpinner';
import AlertAccent from '../components/AlertAccent';
import BtnFilled from '../components/BtnFilled';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AlertError from '../components/AlertError';
//https://fkhadra.github.io/react-toastify/introduction/
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const monthdays = [
  { val: 1, opt: 1 },
  { val: 2, opt: 2 },
  { val: 3, opt: 3 },
  { val: 4, opt: 4 },
  { val: 5, opt: 5 },
  { val: 6, opt: 6 },
  { val: 7, opt: 7 },
  { val: 8, opt: 8 },
  { val: 9, opt: 9 },
  { val: 10, opt: 10 },
  { val: 11, opt: 11 },
  { val: 12, opt: 12 },
  { val: 13, opt: 13 },
  { val: 14, opt: 14 },
  { val: 15, opt: 15 },
  { val: 16, opt: 16 },
  { val: 17, opt: 17 },
  { val: 18, opt: 18 },
  { val: 19, opt: 19 },
  { val: 20, opt: 20 },
  { val: 21, opt: 21 },
  { val: 22, opt: 22 },
  { val: 23, opt: 23 },
  { val: 24, opt: 24 },
  { val: 25, opt: 25 },
  { val: 26, opt: 26 },
  { val: 27, opt: 27 },
  { val: 28, opt: 28 },
];

const initIntegrations = [
  {
    i: 0,
    type: 'reporter.mail',
    name: 'Оповещения на email',
    config: { send_at_day: 1, send_to: 'example@yandex.ru' },
  },
  {
    i: 1,
    type: 'mosru',
    name: 'Интеграция с mos.ru',
    config: { send_at_day: 1 },
  },
  {
    i: 2,
    type: 'mvk',
    name: 'Интеграция с Мосводоканал',
    config: { send_at_day: 1, dog_tag: '90037798' },
  },
];

const Integrations = ({ user }) => {
  const [objects, setObjects] = React.useState([]);
  const [loadErr, setLoadErr] = React.useState([]);
  const [loading, setLoading] = React.useState({ getobjects: true });
  const [deviceId, setDeviceId] = React.useState();
  const [integrationType, setIntegrationType] = React.useState(initIntegrations[0].type);
  const [integConf, setIntegConf] = React.useState(initIntegrations[0].config);

  React.useEffect(() => {
    const get = async () => {
      await getObj();
    };
    get();
  }, []);

  const getObj = async () => {
    setLoadErr([]);
    await getObjectsApi(
      async function (data) {
        setLoading({ ...loading, getobjects: false });
        if (data && data.length !== 0) {
          setObjects(data);
          //передадим id первого объекта для поиска для него устройств
          await getDevices(data[0].id);
        }
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setLoading({ ...loading, getobjects: false });
        setLoadErr((p) => [...p, err]);
      }
    );
  };

  const [devices, setDevices] = React.useState([]);
  const getDevices = async (objectId) => {
    setLoadErr([]);
    await getDevicesObjApi(
      async function (data) {
        setDevices(data);
        if (data && data.length !== 0) {
          //установим id выбранного устройства
          setDeviceId(data[0].id);
          await getIntegrations(data[0].id);
        }
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setLoadErr((p) => [...p, err]);
      },
      objectId
    );
  };

  const addIntegration = async () => {
    setLoadErr([]);
    await addIntegrationApi(
      integConf,
      integrationType,
      deviceId,
      async function (data) {
        await getIntegrations(deviceId);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setLoadErr((p) => [...p, err]);
      }
    );
  };

  const addIntegration2 = async () => {
    setLoadErr([]);
    await addIntegrationApi2(
      integConf,
      integrationType,
      deviceId,
      async function (data) {
        await getIntegrations(deviceId);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setLoadErr((p) => [...p, err]);
      }
    );
  };

  const [integrations, setIntegrations] = React.useState([]);
  const getIntegrations = async (devId) => {
    setLoadErr([]);
    setIntegrations([]);
    await getIntegrationsApi(
      function (data) {
        setIntegrations((p) => [...p, ...data]);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setLoadErr((p) => [...p, err]);
      },
      devId
    );

    await getIntegrationsApi2(
      function (data) {
        setIntegrations((p) => [...p, ...data]);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setLoadErr((p) => [...p, err]);
      },
      devId
    );
  };

  const delIntegration = async (int) => {
    setLoadErr([]);

    if (int.key === 'mvk') {
      await delIntegrationApi2(
        int._id,
        int.key,
        int.device_id,
        async function (data) {
          await getIntegrations(deviceId);
        },
        function (err) {
          toast.error(err.error, { hideProgressBar: true });
          setLoadErr((p) => [...p, err]);
        }
      );
    } else {
      await delIntegrationApi(
        int.id,
        int.key,
        int.device_id,
        async function (data) {
          await getIntegrations(deviceId);
        },
        function (err) {
          toast.error(err.error, { hideProgressBar: true });
          setLoadErr((p) => [...p, err]);
        }
      );
    }
  };

  const onObjectChange = async (objectId) => {
    await getDevices(objectId);
  };

  const onDevChange = async (deviceId) => {
    setDeviceId(deviceId);
    await getIntegrations(deviceId);
  };

  const intType = async (i) => {
    setIntegrationType(initIntegrations[i].type);
    setIntegConf(initIntegrations[i].config);
  };
  return (
    <>
      <div>
        <ToastContainer />
      </div>

      <div class="flex min-h-screen p-1 items-start justify-center bg-gradient-to-r from-[#ededed] to-[#dcdcdc]">
        {!loading.getobjects ? (
          <div class="w-full max-w-sm sm:max-w-sm md:max-w-xl lg:max-w-2xl xl:max-w-4xl 2xl:max-w-5xl">
            <div class="bg-white shadow-2xl rounded-lg px-6 pb-6 m-2">
              <p class="p-4 text-center font-bold">Настройки интеграций</p>

              {objects.length !== 0 ? (
                <>
                  <div class="w-full mb-4">
                    <Dropdown
                      label={'Выберите объект'}
                      options={objects}
                      inputCb={(e) => onObjectChange(parseInt(e.target.value))}
                      id="devs"
                      valkey="id"
                      optkey="address"></Dropdown>
                  </div>
                  <div class="w-full mb-4">
                    <Dropdown
                      label={'Выберите устройство'}
                      options={devices}
                      inputCb={(e) => onDevChange(parseInt(e.target.value))}
                      id="devs"
                      valkey="id"
                      emptyoption="К объекту не привязано ни одного устройства"
                      disabled={devices.length === 0}
                      optkey="serial_number"></Dropdown>
                  </div>
                  <div class="w-full mb-4">
                    <Dropdown
                      label={'Выберите тип интеграции'}
                      options={initIntegrations}
                      inputCb={(e) => intType(parseInt(e.target.value))}
                      id="ints"
                      valkey="i"
                      disabled={devices.length === 0}
                      optkey="name"></Dropdown>
                  </div>

                  {devices.length !== 0 && (
                    <>
                      {integrationType === 'reporter.mail' && (
                        <>
                          <table class="table-auto w-full text-base mb-4">
                            <thead>
                              <tr>
                                <th>
                                  <p class="w-12 sm:w-32 lg:w-32 xl:w-auto 2xl:w-auto truncate text-xs sm:text-sm">Email для оповещения</p>
                                </th>
                                <th>
                                  <p class="w-16 sm:w-32 lg:w-32 xl:w-auto 2xl:w-auto truncate text-xs sm:text-sm">День оповещения</p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {integrations.length !== 0 && (
                                <>
                                  {integrations.map((int, index) => {
                                    return (
                                      <>
                                        {int.key === 'reporter.mail' && (
                                          <tr key={index + int.key}>
                                            <td class="text-center border-y">{int.cfg.send_to}</td>
                                            <td class="text-center border-y">{int.cfg.send_at_day}</td>

                                            <td class="text-center border-y">
                                              <DeleteForeverOutlinedIcon
                                                onClick={() => delIntegration(int)}
                                                class="w-6 h-6  fill-black hover:fill-orange-500"></DeleteForeverOutlinedIcon>
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    );
                                  })}
                                </>
                              )}
                            </tbody>
                          </table>

                          <div class="mb-4">
                            <Input
                              label="Email для оповещения"
                              value={integConf?.send_to}
                              inputCb={(e) => setIntegConf({ ...integConf, send_to: e.target.value })}
                              id="email"
                              type="text"
                              placeholder={'someone@example.com'}></Input>
                          </div>
                          <div class="w-full my-4">
                            <Dropdown
                              label={'День оповещения'}
                              options={monthdays}
                              value={integConf?.send_at_day}
                              inputCb={(e) => setIntegConf({ ...integConf, send_at_day: parseInt(e.target.value) })}
                              id="hrs"
                              valkey="val"
                              optkey="opt"></Dropdown>
                          </div>
                          <div class="w-full mb-0">
                            <BtnFilled title="Добавить" btnCb={() => addIntegration()}></BtnFilled>
                          </div>
                        </>
                      )}
                      {integrationType === 'mosru' && (
                        <>
                          <table class="table-auto w-full text-base mb-4">
                            <thead>
                              <tr>
                                <th>
                                  <p class="w-16 sm:w-32 lg:w-32 xl:w-auto 2xl:w-auto truncate text-xs sm:text-sm">День отправки показаний</p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {integrations.length !== 0 && (
                                <>
                                  {integrations.map((int, index) => {
                                    return (
                                      <>
                                        {int.key === 'mosru' && (
                                          <tr key={index + int.key}>
                                            <td class="text-center border-y">{int.cfg.send_at_day}</td>

                                            <td class="text-center border-y">
                                              <DeleteForeverOutlinedIcon
                                                onClick={() => delIntegration(int)}
                                                class="w-6 h-6  fill-black hover:fill-orange-500"></DeleteForeverOutlinedIcon>
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    );
                                  })}
                                </>
                              )}
                            </tbody>
                          </table>

                          <div class="w-full my-4">
                            <Dropdown
                              label={'День отправки показаний'}
                              options={monthdays}
                              value={integConf?.send_at_day}
                              inputCb={(e) => setIntegConf({ ...integConf, send_at_day: parseInt(e.target.value) })}
                              id="hrs"
                              valkey="val"
                              optkey="opt"></Dropdown>
                          </div>
                          <div class="w-full mb-0">
                            <BtnFilled title="Добавить" btnCb={() => addIntegration()}></BtnFilled>
                          </div>
                        </>
                      )}
                      {integrationType === 'mvk' && (
                        <>
                          <table class="table-auto w-full text-base mb-4">
                            <thead>
                              <tr>
                                <th>
                                  <p class="w-12 sm:w-32 lg:w-32 xl:w-auto 2xl:w-auto truncate text-xs sm:text-sm">Номер договора</p>
                                </th>
                                <th>
                                  <p class="w-16 sm:w-32 lg:w-32 xl:w-auto 2xl:w-auto truncate text-xs sm:text-sm">День отправки показаний</p>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {integrations.length !== 0 && (
                                <>
                                  {integrations.map((int, index) => {
                                    return (
                                      <>
                                        {int.key === 'mvk' && (
                                          <tr key={index + int.key}>
                                            <td class="text-center border-y">{int.cfg.dog_tag}</td>
                                            <td class="text-center border-y">{int.cfg.send_at_day}</td>

                                            <td class="text-center border-y">
                                              <DeleteForeverOutlinedIcon
                                                onClick={() => delIntegration(int)}
                                                class="w-6 h-6  fill-black hover:fill-orange-500"></DeleteForeverOutlinedIcon>
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    );
                                  })}
                                </>
                              )}
                            </tbody>
                          </table>

                          <div class="w-full my-4">
                            <Dropdown
                              label={'День отправки показаний'}
                              options={[{ val: 1, opt: 'Всегда первое число месяца' }]}
                              value={integConf?.send_at_day}
                              inputCb={(e) => setIntegConf({ ...integConf, send_at_day: parseInt(e.target.value) })}
                              id="hrs"
                              valkey="val"
                              optkey="opt"></Dropdown>
                          </div>
                          <div class="w-full my-4">
                            <Input
                              label="Номер договора"
                              value={integConf?.dog_tag}
                              inputCb={(e) => setIntegConf({ ...integConf, dog_tag: e.target.value })}
                              id="dog_tag"
                              type="text"
                              placeholder={'90037798'}></Input>
                          </div>
                          <div class="w-full mb-0">
                            <BtnFilled title="Добавить" btnCb={() => addIntegration2()}></BtnFilled>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <AlertAccent
                  title="Объекты не созданны!"
                  text="Для настройки интеграций Вам необходимо добавить хотябы один объект"
                  showAlert={true}></AlertAccent>
              )}
            </div>
          </div>
        ) : (
          <LoadingSpinner error={loadErr} loading={true}></LoadingSpinner>
        )}
      </div>
    </>
  );
};

export default Integrations;
