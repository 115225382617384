import DeviceStatuses from './DeviceStatuses';
//this is counter widget
import React from 'react';
//line-clamp-1 text-lg font-bold text-center
const DeviceValues = ({ device, style }) => {
  return (
    <>
      {device?.type?.key === 'counter' && <p class={style}>{(device?.last_event?.value ? device?.last_event?.value : '-') + ' m³'}</p>}
      {device?.type?.key === 'controller' && <DeviceStatuses device={device} style={style} icon={false}></DeviceStatuses>}
      {device?.type?.sub_type?.key === 'sensor:water:leakage' && <p class={style}>{device?.last_event?.value ? 'Открыт' : 'Закрыт'}</p>}
      {device?.type?.sub_type?.key === 'sensor:temperature' && <p class={style}>{(device?.last_event?.value ? device?.last_event?.value : '-') + ' °C'} </p>}
    </>
  );
};

export default DeviceValues;
