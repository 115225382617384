import React from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

const InputPasswd = ({ label, value, inputCb, id, autocomplete = '' }) => {
  const [type, _setType] = React.useState('password');

  const changeType = () => {
    if (type === 'text') _setType('password');
    if (type === 'password') _setType('text');
  };
  return (
    <>
      <label class="block text-gray-700 text-sm ml-2 mb-2" for={id}>
        {label}
      </label>
      <form class="flex flex-row h-10">
        <input
          value={value}
          onChange={(e) => inputCb(e)}
          class=" text-gray-800 leading-tight pl-4 h-10 border-gray-800  border-y border-l rounded-full rounded-r-none w-full py-1 px-2 outline-none "
          type={type}
          placeholder="********"
          autocomplete={autocomplete}
          name={id}></input>
        <span class="flex items-center select-none  bg-white rounded-full rounded-l-none border-gray-800  border-y border-r px-3 font-bold text-grey-100">
          <RemoveRedEyeOutlinedIcon class="w-6 h-6 fill-gray-500 hover:fill-black" onClick={() => changeType()}></RemoveRedEyeOutlinedIcon>
        </span>
      </form>
    </>
  );
};

export default InputPasswd;
