import { getDevicesEventsApi, getDevicesConsApi } from '../api/api';
import React from 'react';
import { Line, Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import LoadingSpinner from '../components/LoadingSpinner';
import BtnFilled from './BtnFilledSmall';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

Chart.register(zoomPlugin);

const chartTypes = {
  'counter:water:hot': {
    type: 'line',
    labels: [],
    datasets: [
      {
        label: 'Горячая, m³',
        data: [],
        fill: true,
        backgroundColor: 'rgba(252, 165, 165, 0.2)',
        borderColor: 'rgba(239, 68, 68, 1)',
      },
    ],
  },

  'counter:water:cold': {
    type: 'line',
    labels: [],
    datasets: [
      {
        label: 'Холодная, m³',
        data: [],
        fill: true,
        backgroundColor: 'rgba(147, 197, 253, 0.2)',
        borderColor: 'rgba(59, 130, 246, 1)',
      },
    ],
  },

  'counter:water': {
    type: 'line',
    labels: [],
    datasets: [
      {
        label: 'Вода, m³',
        data: [],
        fill: true,
        backgroundColor: 'rgba(156, 163, 175, 0.2)',
        borderColor: 'rgb(156, 163, 175, 1)',
      },
    ],
  },

  'sensor:temperature': {
    type: 'line',
    labels: [],
    datasets: [
      {
        label: 'Температура, °C',
        data: [],
        fill: true,
        backgroundColor: 'rgb(196, 181, 253, 0.2)',
        borderColor: 'rgb(139, 92, 246, 1)',
      },
    ],
  },

  'sensor:water:leakage': {
    type: 'bar',
    labels: [],
    datasets: [
      {
        label: 'История срабатываний',
        data: [],
        fill: true,
        backgroundColor: 'rgba(239, 68, 68, 1)',
      },
    ],
  },

  'counter:water:hot:cons': {
    type: 'bar',
    labels: [],
    datasets: [
      {
        label: 'Суточный расход горячей, m³',
        data: [],
        fill: true,
        backgroundColor: 'rgba(239, 68, 68, 1)',
      },
    ],
  },

  'counter:water:cold:cons': {
    type: 'bar',
    labels: [],
    datasets: [
      {
        label: 'Суточный расход холодной, m³',
        data: [],
        fill: true,
        backgroundColor: 'rgba(59, 130, 246, 1)',
      },
    ],
  },

  'counter:water:cons': {
    type: 'bar',
    labels: [],
    datasets: [
      {
        label: 'Суточный расход воды, m³',
        data: [],
        fill: true,
        backgroundColor: 'rgb(156, 163, 175, 1)',
      },
    ],
  },
};

const options = {
  maintainAspectRatio: false,
  responsive: true,

  elements: {
    point: {
      radius: 2,
    },
    line: {
      borderWidth: 1.5,
    },
  },
  scales: {
    //'y-axis-0': {
    //  position: 'left',
    //},
    x: {
      //type: 'time',
      //time: {
      //  // unit: 'day',
      //  tooltipFormat: 'DD-MM-YYYY HH:mm:ss',
      //  displayFormats: {
      //    millisecond: 'HH:mm:ss.SSS',
      //    second: 'HH:mm:ss',
      //    minute: 'HH:mm',
      //    hour: 'HH',
      //    day: 'DD-MM-YY',
      //    week: 'DD-MM-YY',
      //    month: 'MM-YYYY',
      //    quarter: 'MM-YYYY',
      //    year: 'YYYY',
      //  },
      //},
      ticks: {
        source: 'data',
        display: true,
        maxTicksLimit: 8,
      },
      grid: {
        //color: 'rgba(0, 0, 0, 1)',
      },
    },
    y: {
      //min: 1,
      //max: 200,
      //type: 'logarithmic',
      ticks: {
        //color: 'rgba(0, 0, 0, 1)',
      },
      grid: {
        //color: 'rgba(0, 0, 0, 1)',
      },
    },
  },
  plugins: {
    title: { text: '' },
    legend: { display: true, position: 'bottom' },
    zoom: {
      zoom: {
        wheel: {
          enabled: true, // SET SCROOL ZOOM TO TRUE
          speed: 0.000001,
        },
        pinch: {
          enabled: true,
        },
        drag: {
          enabled: false,
        },
        mode: 'x',
        speed: 100,
      },
      pan: {
        enabled: true,
        mode: 'xy',
        speed: 100,
      },
    },
  },
};

function getTimeFormatted(time) {
  return new Date(time).toLocaleString('default', { day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit' }).replace(',', '');
}

const Chartsjs_ = ({ device, cons, setCons }) => {
  const [chData, setChData] = React.useState({});

  const getData = () => {
    if (cons) {
      getDevicesCons();
    } else {
      getDevicesEvents();
    }
  };

  const [devicesEventsErr, setDevicesEventsErr] = React.useState([]);

  const getDevicesEvents = async () => {
    await getDevicesEventsApi(
      function (data) {
        let devType = device?.type?.sub_type?.key;
        let chart = chartTypes[devType];
        chart.labels = data.map((e) => getTimeFormatted(e.at));
        chart.datasets[0].data = data.map((e) => e.value);
        setChData(chart);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setDevicesEventsErr((p) => [...p, err]);
      },
      device.id
    );
  };

  const getDevicesCons = async () => {
    await getDevicesConsApi(
      function (data) {
        let devType = device?.type?.sub_type?.key + ':cons';
        let chart = chartTypes[devType];
        chart.labels = data.map((e) => getTimeFormatted(e.interval));
        chart.datasets[0].data = data.map((e) => e.consumption);
        setChData(chart);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setDevicesEventsErr((p) => [...p, err]);
      },
      device.id
    );
  };

  const switchType = async () => {
    setChData({});
    setDevicesEventsErr([]);
    setCons(!cons);
  };

  React.useEffect(() => {
    getData();
  }, [cons]);

  return (
    <>
      <div>
        <ToastContainer />
      </div>
      <>
        {chData.labels && chData.datasets[0].data ? (
          <>
            <div class="w-full h-60 sm:h-60 md:h-72 xl:h-96 2xl:h-96">
              {chData.type === 'line' && <Line data={chData} options={options} />}
              {chData.type === 'bar' && <Bar data={chData} options={options} />}
            </div>
          </>
        ) : (
          <div class="flex w-full h-60 sm:h-60 md:h-72 xl:h-96 2xl:h-96 items-center justify-center">
            <LoadingSpinner error={devicesEventsErr} loading={true} style=""></LoadingSpinner>
          </div>
        )}
        {device?.type?.sub_type?.key.includes('counter') && (
          <div class="w-32">
            <BtnFilled title={cons ? 'Показания' : 'Расход'} btnCb={() => switchType()}></BtnFilled>
          </div>
        )}
      </>
    </>
  );
};

export default Chartsjs_;
