import axios from 'axios';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, DEFAULT_REFRESH_INTERVAL_MS, REFRESH_TIMESTAMP_KEY, USE_BEARER } from '../constants';
import { setAuthorizedEvent } from '../App';
import { startRefrashTokenTask2, deleteRefrashTokenTask2 } from '../api/api2';

var refrash_int_inst;

const axiosRequest = axios.create({
  //withCredentials: true,
  baseURL: process.env.REACT_APP_BASE_URL,
});

const setBearerHeaders = () => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  return USE_BEARER ? { headers: { Authorization: 'Bearer ' + token } } : {};
};

const handleError = async (err) => {
  if (err?.response?.status === 401) {
    refreshTokens({ refresh_token: localStorage.getItem(REFRESH_TOKEN_KEY) });
  }
};

export const getUserApi = async (dataCb, errCb) => {
  var headers = setBearerHeaders();
  await axiosRequest
    .get('users/me', headers)
    .then((res) => {
      startRefrashTokenTask();
      startRefrashTokenTask2();
      dataCb(res.data);
    })
    .catch((err) => {
      deleteRefrashTokenTask();
      deleteRefrashTokenTask2();
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        console.log(err);
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const logInApi = async (user, dataCb, errCb) => {
  await axiosRequest
    .post('auth/sign-in', user)
    .then((res) => {
      dataCb(res);
      updateTokens(res.data?.access_token, res.data?.refresh_token);
    })
    .catch((err) => {
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const refreshTokens = async (refresh_token) => {
  const headers = setBearerHeaders();
  await axiosRequest
    .post('auth/refresh', refresh_token, headers)
    .then((res) => {
      updateTokens(res.data.access_token, res.data.refresh_token);
      setAuthorizedEvent(true);
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        setAuthorizedEvent(false);
      }
      var msg = err?.response?.data;
      if (msg) {
        console.log(JSON.stringify(msg));
      } else {
        console.log('server missing or no internet');
      }
    });
};

const updateTokens = async (access_token, refresh_token) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
  localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token);
  localStorage.setItem(REFRESH_TIMESTAMP_KEY, (Date.now() + DEFAULT_REFRESH_INTERVAL_MS).toString());
};

const startRefrashTokenTask = async () => {
  clearInterval(refrash_int_inst);
  refrash_int_inst = setInterval(() => {
    refreshTokens({ refresh_token: localStorage.getItem(REFRESH_TOKEN_KEY) });
  }, DEFAULT_REFRESH_INTERVAL_MS);
};

const deleteRefrashTokenTask = async () => {
  clearInterval(refrash_int_inst);
};

export const logout = async () => {
  await axiosRequest
    .post('auth/logout')
    .then(() => {
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      localStorage.removeItem(REFRESH_TIMESTAMP_KEY);
    })
    .catch((err) => {
      var msg = err?.response?.data;
      if (msg) {
        console.log(JSON.stringify(msg));
      } else {
        console.log('server missing or no internet');
      }
    });
};

export const signUp = async (user, setRes) => {
  await axiosRequest
    .post('auth/signup', user)
    .then((res) => {
      setRes({ status: 'success' });
      updateTokens(res.data.access_token, res.data.refresh_token);
    })
    .catch((err) => {
      var msg = err?.response?.data;
      if (msg) {
        setRes(msg);
      } else {
        setRes({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const signUpApi = async (user, dataCb, errCb) => {
  await axiosRequest
    .post('auth/signup', user)
    .then((res) => {
      dataCb(res);
      updateTokens(res.data?.access_token, res.data?.refresh_token);
    })
    .catch((err) => {
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const verify = async (ticket, setRes, setProgress) => {
  const ticketJson = { ticket: ticket };
  await axiosRequest
    .post('auth/signup/verify', ticketJson)
    .then((res) => {
      setProgress(false);
      setRes(res.data);
    })
    .catch((err) => {
      setProgress(true);
      var msg = err?.response?.data;
      if (msg) {
        setRes(msg);
      } else {
        setRes({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const verifyResetPassApi = async (ticket, dataCb, errCb) => {
  var query = {};
  query.params = {
    ticket,
  };
  await axiosRequest
    .post('auth/password/reset/verify', null, query)
    .then((res) => {
      dataCb(res.data.items);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const resetPassApi = async (ticket, pass, dataCb, errCb) => {
  var passJson = { password: pass };
  var query = {};
  query.params = {
    ticket,
  };
  await axiosRequest
    .post('auth/password/reset', passJson, query)
    .then((res) => {
      dataCb(res.data.items);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

//=====OBJECTS API==============================================

export const getObjectsApi = async (dataCb, errCb) => {
  var headers = setBearerHeaders();
  await axiosRequest
    .get('objects', headers)
    .then((res) => {
      dataCb(res.data.items);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        console.log(err);
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const addObjectApi = async (object, dataCb, errCb) => {
  const headers = setBearerHeaders();
  await axiosRequest
    .post('objects', object, headers)
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const editObjectApi = async (object, dataCb, errCb) => {
  const headers = setBearerHeaders();
  await axiosRequest
    .patch('objects', object, headers)
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const deleteObjectApi = async (id, dataCb, errCb) => {
  const headers = setBearerHeaders();
  var url = 'objects/' + id;
  await axiosRequest
    .delete(url, headers)
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

//=====DEVICES API==============================================

export const getDevicesApi = async (dataCb, errCb) => {
  var headers = setBearerHeaders();
  await axiosRequest
    .get('devices', headers)
    .then((res) => {
      dataCb(res.data.items);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const getDevicesObjApi = async (dataCb, errCb, objectId) => {
  var headers = setBearerHeaders();
  headers.params = {
    objectId,
  };
  await axiosRequest
    .get('devices', headers)
    .then((res) => {
      dataCb(res.data.items);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const addDeviceApi = async (object, dataCb, errCb) => {
  const headers = setBearerHeaders();
  await axiosRequest
    .post('devices', object, headers)
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const editDeviceApi = async (device, dataCb, errCb) => {
  const headers = setBearerHeaders();
  await axiosRequest
    .patch('devices', device, headers)
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const deleteDevApi = async (id, dataCb, errCb) => {
  const headers = setBearerHeaders();
  var url = 'devices/' + id;
  await axiosRequest
    .delete(url, headers)
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const setDevInputsApi = async (id, inputsArray, dataCb, errCb) => {
  const headers = setBearerHeaders();
  var url = 'devices/' + id + '/inputs';
  await axiosRequest
    .post(url, inputsArray, headers)
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const getDevicesEventsApi = async (dataCb, errCb, deviceId) => {
  var headers = setBearerHeaders();
  headers.params = {
    deviceId,
  };
  await axiosRequest
    .get('devices/events', headers)
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const getDevicesConsApi = async (dataCb, errCb, deviceId) => {
  var headers = setBearerHeaders();
  headers.params = {
    deviceId,
  };
  await axiosRequest
    .get('devices/events/stats', headers)
    .then((res) => {
      dataCb(res.data?.stats);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const bindObjDevApi = async (bind, dataCb, errCb) => {
  const headers = setBearerHeaders();
  await axiosRequest
    .post('objects/devices', bind, headers)
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

//POST /api/v1/integrations/:integrationKey?deviceId=123
export const addIntegrationApi = async (integConf, integrationKey, deviceId, dataCb, errCb) => {
  const headers = setBearerHeaders();
  headers.params = {
    deviceId,
  };
  await axiosRequest
    .post('integrations/' + integrationKey, integConf, headers)
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const getIntegrationsApi = async (dataCb, errCb, deviceId) => {
  var headers = setBearerHeaders();
  headers.params = {
    deviceId,
  };
  await axiosRequest
    .get('integrations', headers)
    .then((res) => {
      var r = res.data?.values;
      if (r === null) r = [];
      dataCb(r);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const delIntegrationApi = async (id, integrationKey, deviceId, dataCb, errCb) => {
  const headers = setBearerHeaders();
  headers.params = {
    deviceId,
  };
  await axiosRequest
    .delete('integrations/' + integrationKey + '/' + id, headers)
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

export const resetPassRqApi = async (data, dataCb, errCb) => {
  const headers = setBearerHeaders();
  await axiosRequest
    .post('auth/password/reset/link', data)
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      handleError(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};
