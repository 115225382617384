import * as React from 'react';
import { verifyResetPassApi, resetPassApi } from '../api/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import BtnFilled from '../components/BtnOutlined';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from '../components/LoadingSpinner';
import InputPasswd from '../components/InputPasswd';

import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

const Verify = ({}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = React.useState(true);
  const [ticket, setTicket] = React.useState('');

  const [newPass1, setNewPass1] = React.useState('');
  const [newPass2, setNewPass2] = React.useState('');

  const [err, setErr] = React.useState('');

  React.useEffect(() => {
    const t = searchParams.get('ticket');
    setTicket(t);
    verifyPass(t);
  }, []);

  const [verify, setVerify] = React.useState({});
  const verifyPass = async (t) => {
    setLoading(true);
    await verifyResetPassApi(
      t,
      function (data) {
        setLoading(false);
        setVerify(data);
      },
      function (err) {
        toast.error(err.error ? err.error : err, { hideProgressBar: true });
        setLoading(false);
      }
    );
  };

  const resetPass = async () => {
    setLoading(true);

    await resetPassApi(
      ticket,
      newPass2,
      function (data) {
        setLoading(false);
        setVerify(data);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setErr(err.error);
        console.log(err.error);
        setLoading(false);
      }
    );
  };

  const changePass = async () => {
    resetPass(ticket);
  };

  return (
    <>
      <div>
        <ToastContainer />
      </div>
      <>
        <div class="flex w-full min-h-screen p-4 items-start justify-center bg-gradient-to-r from-[#ededed] to-[#dcdcdc]">
          <div class="w-full max-w-lg">
            <form class="bg-white shadow-2xl rounded-xl border-b border border-gray-200 px-8 pt-6 pb-8 mb-4">
              {loading ? (
                <div class="flex justify-center mb-4">
                  <HourglassTopRoundedIcon class="fill-orange-500 animate-spin w-8 h-8"></HourglassTopRoundedIcon>
                </div>
              ) : (
                <>
                  {verify.status !== 'success' ? (
                    <>
                      <div class="flex mb-4">
                        <CheckCircleOutlineRoundedIcon class="fill-green-500 w-8 h-8"></CheckCircleOutlineRoundedIcon>
                        <p class="text-gray-800 ml-4 mt-0.5 text-lg font-bold truncate">Сброс пароля</p>
                      </div>
                      <div class="mb-4">
                        <InputPasswd
                          label="Введите новый пароль"
                          value={newPass1}
                          inputCb={(e) => setNewPass1(e.target.value)}
                          id="passwordlogin"></InputPasswd>
                      </div>
                      <div class="mb-4">
                        <InputPasswd
                          label="Подтвердите новый пароль"
                          value={newPass2}
                          inputCb={(e) => setNewPass2(e.target.value)}
                          id="passwordlogin"></InputPasswd>
                      </div>

                      {newPass1 === newPass2 && newPass1 !== '' && newPass2 !== '' && (
                        <div class="mt-8">
                          <BtnFilled title="Сменить пароль" btnCb={() => changePass()}></BtnFilled>
                        </div>
                      )}
                    </>
                  ) : (
                    <div class="flex mb-4">
                      <CheckCircleOutlineRoundedIcon class="fill-red-500 w-8 h-8"></CheckCircleOutlineRoundedIcon>
                      <p class="text-gray-800 ml-4 mt-0.5 text-lg font-bold truncate">Ошибка сброса пароля</p>
                    </div>
                  )}
                </>
              )}
            </form>
          </div>
        </div>
      </>
    </>
  );
};

export default Verify;
