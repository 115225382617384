import axios from 'axios';

const axiosRequest = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_YANDEX,
});

export const linkAccountsApi = async (params, dataCb, errCb) => {
  await axiosRequest
    .post('auth/login', params)
    .then((res) => {
      dataCb(res);
      //updateTokens(res.data.access_token, res.data.refresh_token);
    })
    .catch((err) => {
      console.log(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};
