//this is counter widget
import React from 'react';

import WaterDropIcon from '@mui/icons-material/WaterDrop';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';

const DeviceIcons = ({ device }) => {
  return (
    <>
      {device?.type?.sub_type?.key === 'counter:water:cold' && (
        <WaterDropIcon class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7 fill-[#359BFF]"></WaterDropIcon>
      )}
      {device?.type?.sub_type?.key === 'counter:water:hot' && (
        <WaterDropIcon class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7 fill-[#FC3339]"></WaterDropIcon>
      )}
      {device?.type?.sub_type?.key === 'counter:water' && (
        <WaterDropIcon class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7 fill-gray-500"></WaterDropIcon>
      )}
      {device?.type?.key === 'controller' && (
        <MemoryOutlinedIcon class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7 fill-green-500"></MemoryOutlinedIcon>
      )}
      {device?.type?.sub_type?.key === 'sensor:water:leakage' && (
        <>
          {device?.last_event?.value ? (
            <ToggleOnOutlinedIcon class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7 fill-orange-500"></ToggleOnOutlinedIcon>
          ) : (
            <ToggleOffOutlinedIcon class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7 fill-red-500"></ToggleOffOutlinedIcon>
          )}
        </>
      )}
      {device?.type?.sub_type?.key === 'sensor:temperature' && (
        <DeviceThermostatIcon class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7 fill-[#FC3339]"></DeviceThermostatIcon>
      )}
    </>
  );
};

export default DeviceIcons;
