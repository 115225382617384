import React from 'react';
import SpeedIcon from '@mui/icons-material/Speed';
import SensorIcon from '@mui/icons-material/SensorsRounded';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoardOutlined';
import Accordion from '../components/Accordion';
import Device from '../deviceList/Device';

const Devices = ({ accordion, setAccordion, devices, user, getDevices }) => {
  const CategoryIcons = {
    counter: { name: 'Счетчики', icon: SpeedIcon },
    sensor: { name: 'Сенсоры', icon: SensorIcon },
    controller: { name: 'Контроллеры', icon: DeveloperBoardIcon },
  };

  React.useEffect(() => {
    createTypesArray();
  }, [devices]);

  const [types, setTypes] = React.useState([]);

  const createTypesArray = () => {
    var typesArr = [];
    devices.map((dev, index) => {
      typesArr = typesArr.concat(dev.type.key);
    });
    typesArr = Array.from(new Set(typesArr));

    setTypes(typesArr);
  };

  return (
    <>
      {devices.length !== 0 && (
        <>
          {types.map((type, i) => {
            return (
              <Accordion
                accordion={accordion}
                setAccordion={setAccordion}
                id={i}
                Icon={CategoryIcons[type] ? CategoryIcons[type].icon : null}
                name={CategoryIcons[type] ? CategoryIcons[type].name : ''}>
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div class="inline-block min-w-full py-1 sm:px-6 lg:px-8">
                    <>
                      {devices.map((device, index) => {
                        return (
                          type === device.type.key &&
                          React.createElement(Device, {
                            key: index,
                            device: device,
                            user: user,
                            getDevices: getDevices,
                            devices: devices,
                          })
                        );
                      })}
                    </>
                  </div>
                </div>
              </Accordion>
            );
          })}
        </>
      )}
    </>
  );
};

export default Devices;
