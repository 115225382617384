//this is counter widget
import React from 'react';

import { delDevIntegrationApi2 } from '../api/api2';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { editDeviceApi, setDevInputsApi, deleteDevApi } from '../api/api';

import Modal from '../components/Modal';
import ConfirmAlert from '../components/ConfirmAlert';
import AlertAccent from '../components/AlertErrorBox';
import AnyDeviceEditor from './AnyDeviceEditor';
import ControllerEditor from './ControllerEditor';

import DeviceNames from './DeviceNames';

import { empty, zero } from '../regex';

import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const innitialConfiguration = {
  intType: [
    { opt: 'Ежедневно', val: 'D' },
    { opt: 'Еженедельно', val: 'W' },
    { opt: 'Ежемесячно', val: 'M' },
  ],
  writingIntervals: [
    { opt: 'Каждый 1 час', val: 1 },
    { opt: 'Каждые 2 часа', val: 2 },
    { opt: 'Каждые 4 часа', val: 4 },
    { opt: 'Каждые 6 часов', val: 6 },
    { opt: 'Каждые 8 часов', val: 8 },
    { opt: 'Каждые 12 часов', val: 12 },
    { opt: 'Каждые 24 часов', val: 24 },
  ],
  weekDay: [
    { opt: 'Понедельник', val: 0 },
    { opt: 'Вторник', val: 1 },
    { opt: 'Среда', val: 2 },
    { opt: 'Четверг', val: 3 },
    { opt: 'Пятница', val: 4 },
    { opt: 'Суббота', val: 5 },
    { opt: 'Воскресенье', val: 6 },
  ],
  sendHrs: [
    { opt: '00:00', val: 0 },
    { opt: '01:00', val: 1 },
    { opt: '02:00', val: 2 },
    { opt: '03:00', val: 3 },
    { opt: '04:00', val: 4 },
    { opt: '05:00', val: 5 },
    { opt: '06:00', val: 6 },
    { opt: '07:00', val: 7 },
    { opt: '08:00', val: 8 },
    { opt: '09:00', val: 9 },
    { opt: '10:00', val: 10 },
    { opt: '11:00', val: 11 },
    { opt: '12:00', val: 12 },
    { opt: '13:00', val: 13 },
    { opt: '14:00', val: 14 },
    { opt: '15:00', val: 15 },
    { opt: '16:00', val: 16 },
    { opt: '17:00', val: 17 },
    { opt: '18:00', val: 18 },
    { opt: '19:00', val: 19 },
    { opt: '20:00', val: 20 },
    { opt: '21:00', val: 21 },
    { opt: '22:00', val: 22 },
    { opt: '23:00', val: 23 },
  ],
  channels: [
    { val: 1, opt: 1 },
    { val: 2, opt: 2 },
    { val: 3, opt: 3 },
    { val: 4, opt: 4 },
    { val: 5, opt: 5 },
    { val: 6, opt: 6 },
    { val: 7, opt: 7 },
    { val: 8, opt: 8 },
  ],
  monthdays: [
    { val: 1, opt: 1 },
    { val: 2, opt: 2 },
    { val: 3, opt: 3 },
    { val: 4, opt: 4 },
    { val: 5, opt: 5 },
    { val: 6, opt: 6 },
    { val: 7, opt: 7 },
    { val: 8, opt: 8 },
    { val: 9, opt: 9 },
    { val: 10, opt: 10 },
    { val: 11, opt: 11 },
    { val: 12, opt: 12 },
    { val: 13, opt: 13 },
    { val: 14, opt: 14 },
    { val: 15, opt: 15 },
    { val: 16, opt: 16 },
    { val: 17, opt: 17 },
    { val: 18, opt: 18 },
    { val: 19, opt: 19 },
    { val: 20, opt: 20 },
    { val: 21, opt: 21 },
    { val: 22, opt: 22 },
    { val: 23, opt: 23 },
    { val: 24, opt: 24 },
    { val: 25, opt: 25 },
    { val: 26, opt: 26 },
    { val: 27, opt: 27 },
    { val: 28, opt: 28 },
    { val: 29, opt: 29 },
    { val: 30, opt: 30 },
    { val: 31, opt: 31 },
  ],
};

const EditMenu = ({ device, user, getDevices, devices }) => {
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [deviceError, setDeviceError] = React.useState([]);

  const editDev = async () => {
    await setDevInputs();

    if (validateDeviceToEdit()) {
      await editDevice();
      await getDevices();
    }
  };

  const [deviceToEdit, setDeviceToEdit] = React.useState(device);
  const editDevice = async () => {
    setDeviceError([]);
    await editDeviceApi(
      deviceToEdit,
      function (data) {
        setDeviceError([]);
        setShowEditModal(false);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setDeviceError((p) => [...p, err]);
      }
    );
  };

  const validateDeviceToEdit = () => {
    var ret = true;
    if (empty.test(deviceToEdit.serial_number)) {
      setDeviceError((p) => [...p, { error: 'Серийный номер пустой' }]);
      ret = false;
    }
    if (empty.test(deviceToEdit.description)) {
      setDeviceError((p) => [...p, { error: 'Описание пустое' }]);
      ret = false;
    }

    return ret;
  };

  const openEditModal = async () => {
    setDeviceToEdit(device);
    setDeviceError([]);
    setShowEditModal(true);
  };

  const [deviceInputs, setDeviceInputs] = React.useState(device.children);
  const setDevInputs = async () => {
    setDeviceError([]);
    await setDevInputsApi(
      deviceToEdit.id,
      deviceInputs,
      function (data) {},
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setDeviceError((p) => [...p, err]);
      }
    );
  };

  const deleteDev = async () => {
    setDeviceError([]);
    await deleteDevApi(
      device.id,
      function (data) {
        //setShowAlert(false);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setDeviceError((p) => [...p, err]);
      }
    );

    await delDevIntegrationApi2(
      device.id,
      function (data) {
        setShowAlert(false);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setDeviceError((p) => [...p, err]);
      }
    );
  };

  const deleteConfiermed = async () => {
    await deleteDev();
    await getDevices();
  };

  return (
    <>
      <>
        <div>
          <ModeEditOutlineOutlinedIcon
            onClick={() => openEditModal()}
            class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7 fill-black hover:fill-orange-500"></ModeEditOutlineOutlinedIcon>
        </div>
        <div>
          <DeleteForeverOutlinedIcon
            onClick={() => setShowAlert(true)}
            class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7 fill-black hover:fill-orange-500"></DeleteForeverOutlinedIcon>
        </div>

        <ConfirmAlert showAlert={showAlert} setShowAlert={setShowAlert} delCb={() => deleteConfiermed()} title="Удаление устройства" error={deviceError}>
          <AlertAccent text="Вы действительно хотите удалить устройство?" showAlert={true}>
            <DeviceNames device={device} style="line-clamp-1"></DeviceNames>
          </AlertAccent>
        </ConfirmAlert>

        <Modal
          showModal={showEditModal && user.verified_at}
          setShowModal={setShowEditModal}
          saveCb={() => editDev()}
          closeCb={() => getDevices()}
          title="Редактирвание устройства"
          error={deviceError}
          style={1}>
          {device.type.key === 'controller' ? (
            <ControllerEditor
              setDeviceToEdit={setDeviceToEdit}
              deviceToEdit={deviceToEdit}
              devices={devices}
              setError={setDeviceError}
              deviceInputs={deviceInputs}
              setDeviceInputs={setDeviceInputs}
              innitialConfiguration={innitialConfiguration}></ControllerEditor>
          ) : (
            <AnyDeviceEditor setDeviceToEdit={setDeviceToEdit} deviceToEdit={deviceToEdit}></AnyDeviceEditor>
          )}
        </Modal>
      </>
      <div>
        <ToastContainer />
      </div>
    </>
  );
};

export default EditMenu;
