//This is accordion component
//accordion - state {id:true, id:false} - overall json for all accordions in pool
//setAccordion - set state for accordion
//id - specific id for this accordion
//Icon - icon to display
//name - name to display

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const Accordion = ({ accordion, setAccordion, id, Icon, name, children }) => {
  return (
    <>
      <div
        onClick={() => {
          setAccordion({ ...accordion, [id]: accordion[id] ? false : true });
        }}
        class="px-1 py-4 sm:px-2 xl:px-4 2xl:px-4 bg-white w-full flex border-t border-gray-100 justify-between items-center select-none">
        <div class="flex items-center px-1.5 gap-2">
          {Icon && <Icon class="fill-gray-800 w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7"></Icon>}
          <p class="font-bold text-base text-gray-800">{name}</p>
        </div>
        {accordion[id] ? (
          <KeyboardArrowUpIcon class="fill-gray-800 mx-1.5 w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7"></KeyboardArrowUpIcon>
        ) : (
          <KeyboardArrowDownIcon class="fill-gray-800 mx-1.5 w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7"></KeyboardArrowDownIcon>
        )}
      </div>
      {accordion[id] && <div class="px-2">{children}</div>}
    </>
  );
};

export default Accordion;
