import React from 'react';

const Dropdown = ({ label, options, value, inputCb, id, valkey, optkey, disabled = false, emptyoption }) => {
  return (
    <>
      <label class={!disabled ? 'block text-gray-700 text-sm ml-2 mb-2' : 'block text-gray-400 text-sm ml-2 mb-2'} for={id}>
        {label}
      </label>

      <form class="flex flex-row h-10">
        <select
          disabled={disabled}
          value={value}
          onChange={(e) => inputCb(e)}
          class={
            !disabled
              ? 'text-gray-800 bg-white leading-tight pl-4 h-10 border-gray-800 border-y border-l rounded-full rounded-r-none w-full py-1 px-2 outline-none'
              : 'text-gray-400 bg-white leading-tight pl-4 h-10 border-gray-400 border-y border-l rounded-full rounded-r-none w-full py-1 px-2 outline-none'
          }
          id={id}>
          <>
            {options.length === 0 && <option>{emptyoption}</option>}
            {options.map((opt, index) => {
              return (
                <option key={index} value={opt[valkey]}>
                  {opt[optkey]}
                </option>
              );
            })}
          </>
        </select>
        <span
          class={
            !disabled
              ? 'flex items-center select-none bg-white rounded-full rounded-l-none border-gray-800 border-y border-r pr-5'
              : 'flex items-center select-none bg-white rounded-full rounded-l-none border-gray-400 border-y border-r pr-5'
          }></span>
      </form>
    </>
  );
};

export default Dropdown;
