import React from 'react';

const BtnFilled = ({ title, btnCb, disabled = false }) => {
  return (
    <>
      <button
        disabled={disabled}
        onClick={() => btnCb()}
        type="button"
        class="w-full truncate align-middle bg-gray-900 hover:bg-orange-500 text-white py-0 px-0 hover:border-orange-500 rounded-full">
        {title}
      </button>
    </>
  );
};

export default BtnFilled;
