//this is counter widget
import React from 'react';
import DeviceNames from '../deviceList/DeviceNames';
import DeviceIcons from '../deviceList/DeviceIcons';
import DeviceValues from '../deviceList/DeviceValues';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Modal from '../components/Modal';
import Chartjs from '../components/Chartjs';

const Device = ({ device }) => {
  const [showModal, setShowModal] = React.useState(false);
  const [cons, setCons] = React.useState(false);

  return (
    <div class="grid grid-cols-12 items-center border-b p-2 ">
      <DeviceIcons device={device}></DeviceIcons>

      <div class="col-span-6 px-2">
        <p class="truncate">{device?.type?.name}</p>
        <p class="truncate text-xs">{device?.serial_number}</p>
      </div>

      <DeviceValues
        device={device}
        style="text-gray-800 col-span-4 mr-3 justify-self-end text-sm sm:text-sm md:text-sm lg:text-base xl:text-base 2xl:text-base font-bold"></DeviceValues>

      <div>
        <InfoOutlinedIcon
          onClick={() => setShowModal(true)}
          class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7 fill-black hover:fill-orange-500"></InfoOutlinedIcon>
      </div>

      <Modal showModal={showModal} setShowModal={setShowModal} title="История данных устройства">
        <Chartjs device={device} cons={cons} setCons={setCons}></Chartjs>
      </Modal>
    </div>
  );
};

export default Device;
