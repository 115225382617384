//this is counter widget
import React from 'react';
import DeviceNames from '../deviceList/DeviceNames';
import DeviceIcons from '../deviceList/DeviceIcons';
import DeviceValues from '../deviceList/DeviceValues';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Device = ({ device }) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <div class="grid grid-cols-12 items-center border-b p-2 ">
      <DeviceIcons device={device}></DeviceIcons>

      <div class="col-span-6 px-2">
        <p class="truncate">{device?.type?.name}</p>
        <p class="truncate text-xs">{device?.serial_number}</p>
      </div>

      <DeviceValues
        device={device}
        style="text-gray-800 col-span-5 mr-3 justify-self-end text-sm sm:text-sm md:text-sm lg:text-base xl:text-base 2xl:text-base font-bold"></DeviceValues>
    </div>
  );
};

export default Device;
