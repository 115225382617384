//this is counter widget
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Modal from '../components/Modal';
import DeviceCard from './DeviceCard';

import DeviceNames from './DeviceNames';
import DeviceIcons from './DeviceIcons';
import DeviceValues from './DeviceValues';

import EditMenu from './EditMenu';

const Device = ({ device, user, getDevices, devices }) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <div class="grid grid-cols-12 items-center">
      <div class="py-4 px-1 sm:px-4 xl:px-8 2xl:px-8">
        <DeviceIcons device={device}></DeviceIcons>
      </div>

      <div class="col-span-8 px-4">
        <DeviceValues device={device} style="text-gray-800 text-sm sm:text-sm md:text-sm lg:text-base xl:text-lg 2xl:text-lg font-bold"></DeviceValues>
        <DeviceNames device={device} style="line-clamp-1"></DeviceNames>
      </div>

      <div>
        <InfoOutlinedIcon
          onClick={() => setShowModal(true)}
          class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7 fill-black hover:fill-orange-500"></InfoOutlinedIcon>
      </div>

      <Modal showModal={showModal} setShowModal={setShowModal} title="Карточка устройства">
        <DeviceCard device={device}></DeviceCard>
      </Modal>

      <EditMenu device={device} user={user} getDevices={getDevices} devices={devices}></EditMenu>
    </div>
  );
};

export default Device;
