import React from 'react';
//This is crollable tabs component

//border-b-4 - if requered border on btn
const tabActiveStyle =
  'inline-block p-3 lg:p-4 xl:p-4 2xl:p-4 text-orange-500 border border-orange-100 bg-orange-50 rounded-lg font-bold active dark:text-orange-500 dark:border-orange-500 cursor-pointer select-none truncate';
const tabInactiveStyle =
  'inline-block p-3 lg:p-4 xl:p-4 2xl:p-4 border border-white font-bold rounded-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 cursor-pointer select-none truncate';

const ScrollTabs = ({ tabs, setTabs, data, setData, setActiveTabIndex }) => {
  React.useEffect(() => {
    //makeActive(0);
  }, []);

  //changing array element by id without mutation
  const makeActive = (index) => {
    setActiveTabIndex(index);
    setTabs([...tabs.map((tab, i) => ({ ...tab, active: index === i ? true : false }))]);
    setData({ ...data, ...tabs[index] });
  };

  return (
    <>
      {tabs.length !== 0 && (
        <div class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <ul class="flex flex-nowrap overflow-x-scroll">
            {tabs.map((tab, index) => {
              return (
                <li key={index} class="m-0 p-0">
                  <p
                    onClick={() => {
                      makeActive(index);
                    }}
                    class={tab?.active ? tabActiveStyle : tabInactiveStyle}>
                    {tab.address}
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

export default ScrollTabs;
