import React from 'react';

const BtnFilled = ({ title, btnCb, disabled = false, type = "button" }) => {
  return (
    <>
      <button
        disabled={disabled}
        onClick={() => btnCb()}
        type={type}
        class="bg-transparent w-full truncate border border-gray-800 hover:bg-orange-50 text-black font-semibold hover:text-orange-500 py-2 px-4  hover:border-orange-500 rounded-full">
        {title}
      </button>
    </>
  );
};

export default BtnFilled;
