import React from 'react';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';

const Alert = ({ error }) => {
  return (
    <>
      {Array.isArray(error) && error.length !== 0 && (
        <div class="px-4 py-3 leading-normal text-white bg-red-500 hover:bg-red-600 rounded-lg cursor-pointer" role="alert">
          {error.map((err, i) => {
            return (
              <div class="flex flex-row items-center">
                <div>
                  <ErrorOutlinedIcon class="w-4 h-4 fill-white mr-2 animate-pulse"></ErrorOutlinedIcon>
                </div>
                <text class="text-white truncate">{err.error}</text>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Alert;
