import React from 'react';
import ScrollTabs from '../components/ScrollTabs';
import ObjectEditor from './ObjectEditor';
import ObjectDeviceBinder from './ObjectDeviceBinder';
import DeviceList from './DeviceList';
import Modal from '../components/Modal';
import LoadingSpinner from '../components/LoadingSpinner';
import ConfirmAlert from '../components/ConfirmAlert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { empty, zero } from '../regex';

import { getObjectsApi, addObjectApi, editObjectApi, deleteObjectApi } from '../api/api';
import AlertAccent from '../components/AlertErrorBox';

import BtnFilled from '../components/BtnOutlined';

const ObjectsController = ({ user }) => {
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [showDelModal, setShowDelModal] = React.useState(false);

  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const [loading, setLoading] = React.useState({ getobjects: false, addobjects: false, editobjects: false, deleteobjects: false });

  React.useEffect(() => {
    const get = async () => {
      await getObj();
    };
    get();
  }, []);

  //objects - getting objects from backend
  const [objects, setObjects] = React.useState([]); //main variable
  const [objectsErr, setObjectsErr] = React.useState([]); //server respond variable

  const getObj = async () => {
    setLoading({ ...loading, getobjects: true });
    await getObjectsApi(
      function (data) {
        if (data && data.length !== 0) {
          data[activeTabIndex].active = true;
          setObjectToEdit(data[activeTabIndex]);
        } else {
          setObjectToEdit({});
        }
        setObjects(data);
        setLoading({ ...loading, getobjects: false });
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setObjectsErr((p) => [...p, err]);
        setLoading({ ...loading, getobjects: false });
      }
    );
  };

  const getObjFirst = async () => {
    setLoading({ ...loading, getobjects: true });
    await getObjectsApi(
      function (data) {
        if (data && data.length !== 0) {
          data[0].active = true;
          setObjectToEdit(data[0]);
        } else {
          setObjectToEdit({});
        }
        setObjects(data);
        setLoading({ ...loading, getobjects: false });
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setObjectsErr((p) => [...p, err]);
        setLoading({ ...loading, getobjects: false });
      }
    );
  };

  const [objectToAdd, setObjectToAdd] = React.useState({ user_id: user.id, address: '' });
  const [objectToAddErr, setObjectToAddErr] = React.useState([]);
  const addOb = async () => {
    setLoading({ ...loading, addobjects: true });
    setObjectToAddErr([]);
    await addObjectApi(
      objectToAdd,
      function (data) {
        setLoading({ ...loading, addobjects: false });
        setObjectToAddErr([]);
        setShowAddModal(false);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setLoading({ ...loading, addobjects: false });
        setObjectToAddErr((p) => [...p, err]);
      }
    );
  };

  const [objectToEdit, setObjectToEdit] = React.useState({ user_id: user.id });
  const [objectToEditErr, setObjectToEditErr] = React.useState([]);
  const editOb = async () => {
    setLoading({ ...loading, editobjects: true });
    setObjectToEditErr([]);
    await editObjectApi(
      objectToEdit,
      function (data) {
        setObjectToEditErr([]);
        setLoading({ ...loading, editobjects: false });
        setShowEditModal(false);
      },
      function (err) {
        setLoading({ ...loading, editobjects: false });
        setObjectToEditErr((p) => [...p, err]);
      }
    );
  };

  const deleteObj = async () => {
    setObjectsErr([]);
    setLoading({ ...loading, deleteobjects: true });
    await deleteObjectApi(
      objectToEdit.id,
      function (data) {
        setObjectsErr([]);
        setLoading({ ...loading, deleteobjects: false });
        setShowDelModal(false);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setObjectsErr((p) => [...p, err]);
        setLoading({ ...loading, deleteobjects: false });
      }
    );
  };

  const validateObjectToEdit = () => {
    setObjectToEditErr([]);
    var ret = true;
    if (empty.test(objectToEdit.address)) {
      setObjectToEditErr((p) => [...p, { error: 'Адрес пустой' }]);
      ret = false;
    }
    if (!zero.test(objectToEdit.apartment)) {
      setObjectToEditErr((p) => [...p, { error: 'Номер дома пустой' }]);
      ret = false;
    }
    if (empty.test(objectToEdit.payment_code)) {
      setObjectToEditErr((p) => [...p, { error: 'Код плательщика пустой' }]);
      ret = false;
    }
    return ret;
  };

  const validateObjectToAdd = () => {
    setObjectToAddErr([]);
    var ret = true;
    if (empty.test(objectToAdd.address)) {
      setObjectToAddErr((p) => [...p, { error: 'Адрес пустой' }]);
      ret = false;
    }
    if (!zero.test(objectToAdd.apartment)) {
      setObjectToAddErr((p) => [...p, { error: 'Номер дома пустой' }]);
      ret = false;
    }
    if (empty.test(objectToAdd.payment_code)) {
      setObjectToAddErr((p) => [...p, { error: 'Код плательщика пустой' }]);
      ret = false;
    }
    return ret;
  };

  const addObj = async () => {
    if (validateObjectToAdd()) {
      await addOb();
      await getObjFirst();
    }
  };

  const editObj = async () => {
    if (validateObjectToEdit()) {
      await editOb();
      await getObj();
    }
  };

  const add = async () => {
    setObjectToAddErr([]);
    setShowAddModal(true);
  };

  const edit = async () => {
    setObjectToEditErr([]);
    setShowEditModal(true);
  };

  const del = async () => {
    setShowDelModal(true);
  };

  const deleteConfiermed = async () => {
    await deleteObj();
    await getObjFirst();
  };

  return (
    <>
      <div>
        <ToastContainer />
      </div>
      <div class="flex min-h-screen p-1 items-start justify-center bg-gradient-to-r from-[#ededed] to-[#dcdcdc]">
        <div class="w-full max-w-sm sm:max-w-sm md:max-w-xl lg:max-w-2xl xl:max-w-4xl 2xl:max-w-5xl">
          {!loading.getobjects ? (
            <>
              <div class="bg-white shadow-2xl rounded-lg m-2">
                <ScrollTabs
                  tabs={objects}
                  setTabs={setObjects}
                  data={objectToEdit}
                  setData={setObjectToEdit}
                  setActiveTabIndex={setActiveTabIndex}></ScrollTabs>

                {objectToEdit.address && (
                  <div class="flex justify-center">
                    <div class="grid justify-items-center grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 mt-4 w-10/12 gap-1">
                      <div>
                        <p class="bg-orange-50 text-gray-800 me-2 px-2.5 py-0.5 rounded-full line-clamp-1">
                          {'Адрес: ' + objectToEdit.address + '/' + objectToEdit.apartment}
                        </p>
                      </div>

                      <div>
                        <p class="bg-orange-50 text-gray-800 me-2 px-2.5 py-0.5 rounded-full line-clamp-1">{'Код плательщика: ' + objectToEdit.payment_code}</p>
                      </div>
                    </div>
                  </div>
                )}

                <DeviceList devices={[]}></DeviceList>
                <div
                  class={
                    objects.length !== 0
                      ? 'grid justify-items-center p-2 grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3'
                      : 'grid justify-items-center p-2 grid-cols-1'
                  }>
                  <div class="px-2 pt-2 pb-2 w-full">
                    <BtnFilled title="Добавить новый объект" btnCb={() => add()}></BtnFilled>
                  </div>
                  {objects.length !== 0 && (
                    <>
                      <div class="px-2 pt-2 pb-2 w-full">
                        <BtnFilled title="Редактировать выбранный объект" btnCb={() => edit()}></BtnFilled>
                      </div>
                      <div class="px-2 pt-2 pb-2 w-full">
                        <BtnFilled title="Удалить выбранный объект" btnCb={() => del()}></BtnFilled>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <LoadingSpinner error={objectsErr} loading={true}></LoadingSpinner>
          )}
        </div>

        <Modal showModal={showAddModal && !user.verified_at} setShowModal={setShowAddModal} title="Добавление объекта">
          <AlertAccent
            title="Невозможно добавить объект!"
            text="Аккаунт не подтвержден. Для подтверждения аккаунта перейдите пожалуйста по ссылке, отправленной на Вашу почту"
            showAlert={true}></AlertAccent>
        </Modal>

        <Modal
          showModal={showAddModal && user.verified_at}
          setShowModal={setShowAddModal}
          saveCb={() => addObj()}
          closeCb={() => getObj()}
          title="Добавление объекта"
          error={objectToAddErr}>
          <ObjectEditor setObject={setObjectToAdd} object={objectToAdd}></ObjectEditor>
        </Modal>

        <Modal
          showModal={showEditModal && user.verified_at}
          setShowModal={setShowEditModal}
          saveCb={() => editObj()}
          closeCb={() => getObj()}
          title="Редактирование объекта"
          error={objectToEditErr}>
          <ObjectEditor setObject={setObjectToEdit} object={objectToEdit}></ObjectEditor>
        </Modal>

        <ConfirmAlert showAlert={showDelModal} setShowAlert={setShowDelModal} delCb={() => deleteConfiermed()} title="Удаление объекта" error={objectsErr}>
          <AlertAccent text="Вы действительно хотите удалить объект?" showAlert={true}>
            <p>{'Адрес: ' + objectToEdit.address + '/' + objectToEdit.apartment}</p>
            <p>{'Все привязанные к данному объекту устройства также будут удалены!'}</p>
          </AlertAccent>
        </ConfirmAlert>
      </div>
    </>
  );
};

export default ObjectsController;
